@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
dl,
dd,
dt,
figure,
figcaption,
blockquote,
fieldset,
pre,
hr,
a,
form,
legend,
button,
input,
textarea,
select {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

body{
    max-width: 100%;

}

img{
    max-width: 100%;
}

/* inicio hero */
.hero {
    position: relative;
    background: linear-gradient(to left, #81B195, #006699);
}

.backgroundImage {
    position: relative; 
    
}

.menu {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    top: 6%;

    
}

.logo{
    margin-left: 2%;
}
.buttonPlatform{
    margin-right: 2%;
  
}

.buttonPlatform button{
    height: 35px;
    width: 120px;
    border-style: none;
    border-radius: 5px;
    background-color: #fff;
    color: #006699;
    cursor: pointer;
}

.heroDetails {
    position: absolute;
    display: flex;
    justify-content: center; 
    top: 30%;
    left: 50%;
    width: 90%;
    transform: translateX(-50%); 
}
.content {
    width:50%;
    margin-top: 5%;
}
.heroDetails .content h1{
    font-size: 30px;
}

.notebook {
    width: 80vh;
    margin-right: 5%;
    margin-left: 20%;
}

.heroDetails button{
    height: 35px;
    width: 120px;
    margin-top: 2%;
    border-style: none;
    border-radius: 5px;
    background-color: #fff;
    color: #81B195;
    cursor: pointer;
}

.heroDetails .content h1{
    color: #fff;
    font-size: 42px;
    margin-bottom: 2%;
    font-family: 'Montserrat', sans-serif;
}

.heroDetails .content h2{
    color: #fff;
    font-size: 25px;
    margin-bottom: 2%;
    font-family: 'Montserrat', sans-serif;
}

.heroDetails p{
    font-size: 20px;
    color:#fff;
    line-height: 30px;
    font-family: 'Montserrat', sans-serif;

   
}
/* final hero */

/* inicio fluxogram */
.fluxogram {
    justify-content: center;
    align-items: center;
    text-align: center;
}

.text {
    font-family: 'Montserrat', sans-serif;
    margin-top: 5%;
    font-size: 18px;
    color:#006699 ;
    margin-bottom: 2vh;
}

.fluxogramImg{
    margin-bottom: 5%;
    margin-left: 20%;
    margin-right: 20%;
    display: flex;
}

.fluxogramImg .arrow img {
    transform: rotate(270deg); /* Rotaciona a imagem em 90 graus */
    margin-top: 5vh;
    margin-left: 2vh;
    margin-right: 2vh;
  }


/* final fluxogram */

/* inicio gif */
.gif{
    background: linear-gradient(to left, #81B195, #006699);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.gifContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.gifContent p {
    margin-top: 5%;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    line-height: 30px;
}

.gifContent iframe {
    margin-top: 5%;
  
}

.lists{
    display: flex;
    margin-top: 5%;
    margin-bottom: 5%;
    width: 100%;
   
}

.list1{
    margin-right: 5%;
    width: 50%;
    padding-left:  20%;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    line-height: 30px;
    font-weight: bold;
}

.list2{
    width: 50%;
    margin-left: 2px;
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    line-height: 30px;
    font-weight: bold;
}

.contentGif {
   
    margin: 0 auto;
    text-align: center;
    margin-bottom: 5%;
}

.contentGif button {
    height: 40px;
    width: 170px;
    margin-top: 2%;
    margin-bottom: 2%;
    border-style: none;
    border-radius: 5px;
    background-color: #fff;
    color: #006699;
    cursor: pointer;
}

.contentGif em {
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    
}
/* final gif */

 /* inicio contactUs */
.functions{
    display: flex;
    margin-left: 9%;
    margin-bottom: 5%;
}
.text2 {
    color: #006699;
    font-family: 'Montserrat', sans-serif;
   
    margin-top: 5%;
    text-align: center;
}

.text2 span {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    line-height: 30px;
}
.functionsDiv{
    margin-right: 2%;
    margin-top: 5%;
    width: 20%;
    border-radius: 5px;
    box-shadow: 0px 3px 3px 3px rgba(0, 0, 0, 0.1);
    
}

.functionsDiv img{
    margin-top:10%;
    margin-left: 10%; 
}

.functionsDiv  p{
    margin-left: 10%; 
    font-weight: bold;
}

.functionsDiv2{
    margin-right: 2%;
    margin-top: 5%;
    margin-left: 10%;
    width: 20%;
    border-radius: 5px;
    box-shadow: 0px 3px 3px 3px rgba(0, 0, 0, 0.1);
}

.functionsDiv2 img{
    margin-top:10%;
    margin-left: 10%; 
 }
 .functionsDiv2  p{
    margin-left: 10%; 
    font-weight: bold;
}
.functionsDiv3{
    margin-right: 2%;
    margin-left: 10%;
    margin-top: 5%;
    width: 20%;
    border-radius: 5px;
    box-shadow: 0px 3px 3px 3px rgba(0, 0, 0, 0.1);
    
}

.functionsDiv3 img{
    margin-top:10%;
    margin-left: 10%; 
 }
 .functionsDiv3  p{
    margin-left: 10%; 
    font-weight: bold;
}
.functionsDiv p{
    font-family: 'Montserrat', sans-serif;
    margin-top: 4%;
    font-size: 15px;
    list-style: none;
    color:#006699 ;
    line-height: 20px;
}
.functionsDiv2 p{
    font-family: 'Montserrat', sans-serif;
    margin-top: 4%;
    font-size: 15px;
    list-style: none;
    color:#006699 ;
    line-height: 20px;
}
.functionsDiv3 p{
    font-family: 'Montserrat', sans-serif;
    margin-top: 4%;
    font-size: 15px;
    list-style: none;
    color:#006699 ;
    line-height: 20px;
}
.functionsDiv img{
    height: 25%;

}
.functionsDiv2 img{
    height: 25%;

}
.functionsDiv3 img{
    height: 25%;

}
/* final contactUs */

/* inicio speak with us */
.speakWithUs {
    background: linear-gradient(to left, #81B195, #006699);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
}

.receba {
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    text-align: center; 
    margin-top: 4%;
}

.inputButton {
    display: flex;
    margin-top: 1%;
    align-items: center; 
    margin-bottom: 3%;
}

.inputButton input[type="text"],
.inputButton button {
    margin: 5px; 
    outline: none; 
    margin-bottom: 10%;
}
.inputButton input{
    width: 300px;
    height: 25px;
    border-radius: 5px;
    border-style: none;
    background: linear-gradient(to left, #81B195, #006699);
    border: 1px solid #fff;
}

.inputButton input[type="text"]::placeholder {
    color: #afaaaa; 
    padding-left:5%;
}

.inputButton button {
    height: 30px;
    width: 80px;
    border-style: none;
    border-radius: 5px;
    background-color: #fff;
    color: #006699;
    cursor: pointer;
}
/* final speak with us */

/* inicio who trust */
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.container .company{
    display: flex;
}
.whoTrust {
    text-align: center;
    margin-top: 5%;
    color:#006699 ;
    font-family: 'Montserrat', sans-serif;
}
.images1 {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    margin-bottom: 20px; 
}

.images1 img {
    margin: 0 4vh; 
    width: 50vh;
    margin-top: 2vh;
}
.images2 {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    margin-bottom: 20px; 
    margin-left: -3vh;
}

.images2 img {
    margin: 0 4vh; 
    width: 50vh;
    margin-top: 2vh;
}
/* final who trust */

/* inicio footer */
.company {
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 5%;
}

.footer {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 5%;
    margin-bottom: 3%;
}

.imageFooter {
    display: flex;
    justify-content: center;
    align-items: center;
}
.imageFooter div{
    width: 25vh;
}
.imageFooter .direitos{
    display: block;
}

.direitos{
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.divisao{
    margin-left: 8vh;
}
.divisao img{
    margin-right: 2vh;
}

.direitos img{
    width: 25vh;
    margin-top: 10vh;
}
/* final footer */

@media only screen and (max-width: 600px)  {
    iframe{
        width: 30vh;
    }
    .hero{
        height: 40vh;
    }
 
    .hero .menu .logo img{
        height: 6vh;
    }

    #btnPlatform{
        width: 12vh;
        font-size: 1.5vh;
        height: 4vh;
    }
    .heroDetails .content h1 {
        font-size: 2.5vh;
        margin-bottom: 1vh;
    
    }
    .heroDetails .content h2 {
        font-size: 1.2vh;
        margin-bottom: 1vh;
        width: 40vh;
    }
    .heroDetails .content p {
        font-size: 1.2vh;
        line-height: 1.5vh;
        width: 40vh;
    
    }
   .heroDetails{
    display: block;
   }
   .heroDetails .notebook img{
    width: 20vh;
    height: 20vh;
    padding-right: 1vh;

   }
   .hero {
    position: relative;
    height: 50vh;
    overflow: hidden; 
  }

  .backgroundImage {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .backgroundImage img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }
  #btnWhatsapp{
    width: 10vh;
    height: 4vh;
    font-size: 1.5vh;
    margin-top: 1vh;
  }
  .heroDetails .notebook {
    right: 1%;
    width: 40vh;
    margin-left: 10vh;
  }
  .heroDetails .notebook img{
    height: 20vh;
    width: 30vh;
  }
  .text{
    font-size: 1.5vh;
    width: 29vh;
    margin-right: 2vw;
    margin-left: 5vh;
  }
  .fluxogramImg {
    display: block;
  }


  .fluxogramImg .arrow img {
    transform: rotate(360deg);
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
  .gifContent p{
    width: 30vh;
    font-size: 1.5vh;
    line-height: 2.5vh;
  }
  .gifContent iframe{
    width: 40vh;
  }
  .lists{
    display: block;
    width: 40vh;

    font-size: 1.3vh;
   
  }
  .list1{
    width: 30vh;
    text-align: center;
    margin-left: 0px;
    padding-left:4vh ;
  }
  
  .list2{
    width: 30vh;
    text-align: center;
    margin-left: 0px;
    padding-left:4vh ;
  }
 
   .contentGif button {
    width: 19vh;
    font-size: 1.5;
    height: 4vh;
}

  .contactUs .text2{
    font-size: 1.5vh;
    width: 35vh;
    margin-left: 4vh;
  }
  .functions{
    display: block;
  }
  .functionsDiv{
    width: 18vh;
    height: 20vh;
    margin-left: 9vh;
    margin-bottom: 3vh;
  }
  .functionsDiv img{
    height: 6vh;
  }
  .functionsDiv p {
    font-size: 1.5vh;
  }
  .functionsDiv2{
    width: 18vh;
    height: 20vh;
    margin-top: 10vhvh;
    margin: 0;
    margin-bottom: 3vh;
  }
  .functionsDiv img{
    height: 6vh;
  }
  .functionsDiv2 p {
    font-size: 1.5vh;
  }
  .functionsDiv3{
    width: 18vh;
    height: 20vh;
    margin-left: 1vh;
    margin-bottom: 3vh;
  }
  .functionsDiv3 img{
    height: 6vh;
  }
  .functionsDiv3 p {
    font-size: 1.5vh;
  }
  .speakWithUs .receba{
    margin-top: 5vh;
    font-size: 1.5vh;
  }
 .speakWithUs .inputButton input{
    margin-left: 4vh;
    width: 25vh;
}
.whoTrust p {
    font-size: 1.5vh;
}
.container .company .images img{
    display: inline-block;
}
.company{
    display: block !important;
}
.imageFooter{
    display: block;
}

.fluxogram {
    text-align: center;
    padding: 20px; 
}

.fluxogram .text {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contactUs .text2 {
    width: 80%; 
    text-align: center;
    margin: 0 auto; 
    font-size: 1.5vh; 
  }
  .contactUs .text2 p {
    margin-top: 5vh;
  }

  .contactUs .functions {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
  }
.functions {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    }

.functionsDiv {
    margin: 10px;
    margin-bottom: 5vh;
    margin-top: 4vh;
    width: 24vh;

    margin-left: -2vh;
    }
.functionsDiv2{
    width: 24vh;
    margin-left: -3vh;
}
.functionsDiv3{
    width: 24vh;
    margin-left: -2vh;
}

.imageFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.divisao{
    margin-left: 4vh;
}
.imageFooter > div {
     margin-bottom: 2vh;
}
.footer3 img{
    width: 45vh;
}
.divisao{
    margin-left: 9vh;
}
#etapa5{
    width: 20vh;
}
#etapa3{
    width: 22vh;
}
#etapa2{
    width: 22vh;
}
#etapa1{
    width: 22vh;
}
#etapa5{
    width: 19vh;
}


.images1{
    width: 50vw;
}
.images2 {
    width: 60vw;
   
}

}

@media only screen and (min-width: 601px) and (max-width: 900px) {
    .hero{
        height: 40vh;
    }
 
    .hero .menu .logo img{
        height: 8vh;
    }

    #btnPlatform{
        width: 12vh;
        font-size: 1.5vh;
        height: 4vh;
    }
    .heroDetails .content h1 {
        font-size: 3vh;
        margin-bottom: 1vh;
    
    }
    .heroDetails .content  {
        width: 60vw;
    
    }
    .heroDetails .content h2 {
        font-size: 1.5vh;
        margin-bottom: 1vh;
        width: 40vh;
    }
    .heroDetails .content p {
        font-size: 1.3vh;
        line-height: 1.5vh;
        width: 45vh;
    
    }
   .heroDetails{
    display: block;
   }
   .heroDetails .notebook img{
    width: 20vh;
    height: 20vh;
    margin-left: 20vh;
   }
 
   .hero {
    position: relative;
    height: 50vh;
    overflow: hidden; 
  }

  .backgroundImage {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .backgroundImage img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }
  #btnWhatsapp{
    width: 10vh;
    height: 4vh;
    font-size: 1.5vh;
    margin-top: 1vh;
  }
  .heroDetails .notebook {
    right: 1%;
    width: 40vh;
    margin-left: 10vh;
  }
  .heroDetails .notebook img{
    height: 20vh;
    width: 30vh;
  }
  .gif .lists .list1{
    width: 30vw;
    padding-left: 13vh;
  }

  .gif .lists .list2{
    margin-right: 2vw;
    width: 40vw;
  }
  .divisao {
    display: flex;
    width: 25vw;
  }
 .images1{
    margin-right: 9vw;
 }
 .fluxogramImg {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.arrow {
    display: flex;
    justify-content: center;
    align-items: center;
}
#etapa1{
    height: 20vh;
}
#etapa2{
    height: 20vh;
}
#etapa3{
    height: 20vh;
}
#etapa4{
    height: 18vh;
}
#etapa5{
    height: 18vh;
}
.fluxogramImg .arrow img {
    transform: rotate(360deg); 
    margin-bottom: 3vh;
  }
  }

  @media only screen and (min-width: 901px) and (max-width: 1080px){
    .hero{
        height: 40vh;
    }
 
    .hero .menu .logo img{
        height: 8vh;
    }

    #btnPlatform{
        width: 12vh;
        font-size: 1.5vh;
        height: 4vh;
    }
    .heroDetails .content h1 {
        font-size: 3vh;
        margin-bottom: 1vh;
    
    }
    .heroDetails .content  {
        width: 60vw;
    
    }
    .heroDetails .content h2 {
        font-size: 1.5vh;
        margin-bottom: 1vh;
        width: 40vh;
    }
    .heroDetails .content p {
        font-size: 1.3vh;
        line-height: 1.5vh;
        width: 45vh;
    
    }
   .heroDetails{
    display: block;
   }
   .heroDetails .notebook img{
    width: 20vh;
    height: 20vh;
    margin-left: 20vh;
   }
 
   .hero {
    position: relative;
    height: 50vh;
    overflow: hidden; 
  }

  .backgroundImage {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .backgroundImage img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }
  #btnWhatsapp{
    width: 10vh;
    height: 4vh;
    font-size: 1.5vh;
    margin-top: 1vh;
  }
  .heroDetails .notebook {
    right: 1%;
    width: 40vh;
    margin-left: 10vh;
  }
  .heroDetails .notebook img{
    height: 20vh;
    width: 30vh;
  }
  .fluxogramImg {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.text{
    font-size: 1.5vh;
}
.arrow {
    display: flex;
    justify-content: center;
    align-items: center;
}
#etapa1{
    height: 18vh;
}
#etapa2{
    height: 18vh;
}
#etapa3{
    height: 18vh;
}
#etapa4{
    height: 16vh;
}
#etapa5{
    height: 16vh;
}
.gifContent{
    font-size: 1.5vh;
}
.text2{
    font-size: 1.5vh;
}
.functionsDiv p{
    font-size: 1.4vh;
    line-height: 2vh;
}
.functionsDiv2 p{
    font-size: 1.4vh;
    line-height: 2vh;
}
.functionsDiv3 p{
    font-size: 1.4vh;
    line-height: 2vh;
}
.images1{
    margin-right: 8vw;
}
.divisao {
    display: flex;
    width: 25vw;
  }
  .whoTrust{
    font-size: 1.5vh;
  }
  .receba{
    font-size: 1.5vh;
  }
  .contentGif em{
    font-size: 1.3vh;
  }
 .contentGif #btnPlatform2 {
    font-size: 1.3vh;
    width: 20vw;
  }
  .inputButton button{
    font-size: 1.4vh;
  }

  }

  @media only screen and (max-width: 376px){
    .fluxogram .text{
        margin-left: 18vw;
    }

  #btnPlatform2{
    font-size: 1.6vh;
  }
  .contentGif em{
    font-size: 1.3vh;
  }
  #etapa4{
    height: 13vh;
  }
}
@media only screen and (max-width: 360px){
    .fluxogram .text{
        margin-left: 15vw;
    }

  #etapa4{
    height: 12vh;
  }
}
